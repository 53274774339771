import React from 'react';

const IconLogo = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 500 369.7">
    {/* <style type="text/css">
	.st0{fill:#64FFDA;}
</style> */}
    <g id="Logo" transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          fill="#64FFDA"
          className="st0"
          d="M350.1,21v328h-328V21H350.1 M357.1,14h-342v342h342V14L357.1,14z"
        />
      </g>
      <path
        fill="#64FFDA"
        className="st0"
        d="M299.5,356h-33.8V220.5h-50.9v-33.9h135.5v33.9h-50.8V356z"
      />
      <path
        fill="#64FFDA"
        className="st0"
        d="M397.1,356h-32.3V179.1h32.3V235c3.9-2.9,7.8-5,11.9-6.2c4.1-1.2,8.3-1.8,12.5-1.8c7.8,0,15.1,1.5,22,4.4
		c6.9,3,12.8,7,17.9,12.2c5.1,5.2,9.1,11.2,12.1,18.1c3,6.9,4.4,14.2,4.4,22V356h-32.5v-72.3h0.2c0-3.3-0.6-6.4-1.9-9.4
		c-1.3-3-3-5.5-5.2-7.7c-2.2-2.2-4.8-3.9-7.7-5.2c-2.9-1.3-6-1.9-9.3-1.9c-3.4,0-6.6,0.6-9.6,1.9c-3,1.3-5.6,3-7.8,5.2
		c-2.2,2.2-3.9,4.8-5.2,7.7c-1.3,3-1.9,6.1-1.9,9.4V356z"
      />
    </g>
  </svg>

  // <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 369.67">
  //   <title>Mesa de trabajo 1</title>
  //   <path class="cls-1" d="M350.09,21V349h-328V21h328m7-7h-342V356h342V14Z" />
  //   <path class="cls-1" d="M299.5,356H265.71V220.48H214.79V186.57H350.3v33.91H299.5Z" />
  //   <path
  //     class="cls-1"
  //     d="M397.09,356H364.84V179.13h32.25V235a37.73,37.73,0,0,1,11.94-6.2A43.61,43.61,0,0,1,421.55,227a55,55,0,0,1,22,4.43,56,56,0,0,1,17.9,12.17,58.28,58.28,0,0,1,12.05,18.08,55.37,55.37,0,0,1,4.43,22V356H445.42V283.69h.23a23.65,23.65,0,0,0-1.89-9.39,24.36,24.36,0,0,0-12.88-12.94,23.23,23.23,0,0,0-9.33-1.89A24.21,24.21,0,0,0,399,274.3a23.65,23.65,0,0,0-1.89,9.39Z"
  //   />
  // </svg>

  // <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
  //   <title>Logo</title>
  //   <g id="Logo" transform="translate(-8.000000, -2.000000)">
  //     <g transform="translate(11.000000, 5.000000)">
  //       <path
  //         d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
  //         fill="#64FFDA"
  //       />
  //       <polygon
  //         id="Shape"
  //         stroke="#64FFDA"
  //         strokeWidth="5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         points="39 0 0 22 0 67 39 90 78 68 78 23"
  //       />
  //     </g>
  //   </g>
  // </svg>
);

export default IconLogo;
