import React from 'react';

const IconLoader = () => (
  <svg
    version="1.1"
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 500 369.7">
    {/* <style type="text/css">
	.st0{fill:#64FFDA;}
</style> */}
    <g>
      <g>
        <path
          fill="#64FFDA"
          className="st0"
          d="M350.1,21v328h-328V21H350.1 M357.1,14h-342v342h342V14L357.1,14z"
        />
      </g>
      <path
        id="B"
        transform="translate(11.000000, 5.000000)"
        fill="#64FFDA"
        className="st0"
        d="M299.5,356h-33.8V220.5h-50.9v-33.9h135.5v33.9h-50.8V356z"
      />
      <path
        id="B"
        transform="translate(11.000000, 5.000000)"
        fill="#64FFDA"
        className="st0"
        d="M397.1,356h-32.3V179.1h32.3V235c3.9-2.9,7.8-5,11.9-6.2c4.1-1.2,8.3-1.8,12.5-1.8c7.8,0,15.1,1.5,22,4.4
		c6.9,3,12.8,7,17.9,12.2c5.1,5.2,9.1,11.2,12.1,18.1c3,6.9,4.4,14.2,4.4,22V356h-32.5v-72.3h0.2c0-3.3-0.6-6.4-1.9-9.4
		c-1.3-3-3-5.5-5.2-7.7c-2.2-2.2-4.8-3.9-7.7-5.2c-2.9-1.3-6-1.9-9.3-1.9c-3.4,0-6.6,0.6-9.6,1.9c-3,1.3-5.6,3-7.8,5.2
		c-2.2,2.2-3.9,4.8-5.2,7.7c-1.3,3-1.9,6.1-1.9,9.4V356z"
      />
    </g>
  </svg>

  // <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  //   <title>Loader Logo</title>
  //   <g>
  //     <g id="B" transform="translate(11.000000, 5.000000)">
  //       <path
  //         d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
  //         fill="#64FFDA"
  //       />
  //     </g>
  //     <path
  //       stroke="#64FFDA"
  //       strokeWidth="5"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       d="M 50, 5
  //                 L 11, 27
  //                 L 11, 72
  //                 L 50, 95
  //                 L 89, 73
  //                 L 89, 28 z"
  //     />
  //   </g>
  // </svg>
);

export default IconLoader;
